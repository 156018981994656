import { createSlice } from "@reduxjs/toolkit";
export const loginSlice = createSlice({
  name: "login",
  initialState: {
    isLogin: false
    // isLogin: true
  },
  reducers: {
    setIsLogin: (state, action) => {
      state.isLogin = action.payload;
    },
  },
});

export const selectLogin = (state: { login: any}) => state.login;
export const { setIsLogin } = loginSlice.actions;
export default loginSlice.reducer; 