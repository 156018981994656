import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import intervalPlural from "i18next-intervalplural-postprocessor";
import translation_EN from "./locales/en.json";
import translation_zhHK from "./locales/zhHK.json";
import translation_zhCN from "./locales/zhCN.json";


const resources = {
  en: {
    translation: translation_EN,
  },
  zhHK: {
    translation: translation_zhHK,
  },
  zhCN: {
    translation: translation_zhCN,
  },
};

i18n.use(initReactI18next).use(LanguageDetector).init({
  resources,
  lng: "zhHK",
  fallbackLng: "en",
});