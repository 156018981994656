import { createSlice } from "@reduxjs/toolkit";
export const orderRecordsSlice = createSlice({
  name: "orderRecords",
  initialState: {
    lastYearSelected: true,
    monthSelected: false,
    monthSelect: { year: '', month: '' },
    orderRecordsData: []
  },
  reducers: {
    setLastYearSelected: (state, action) => {
      state.lastYearSelected = action.payload;
    },
    setMonthSelected: (state, action) => {
      state.monthSelected = action.payload;
    },
    setMonthSelect: (state, action) => {
      state.monthSelect = action.payload;
    },
    setOrderRecordsData: (state, action) => {
      state.orderRecordsData = action.payload;
    }
  },
});

export const selectOrderRecords = (state: { orderRecords: any}) => state.orderRecords;
export const { setLastYearSelected, setMonthSelected, setMonthSelect, setOrderRecordsData } = orderRecordsSlice.actions;
export default orderRecordsSlice.reducer; 