import { configureStore } from "@reduxjs/toolkit";
import todoReducer from "./slice/todo";
import loginReducer from "./slice/login";
import productTransactionDataReducer from './slice/productTransactionData';
import orderRecordsReducer from './slice/orderRecords';
import tabNavReducer from './slice/tabNav';

export default configureStore({
  reducer: {
    todo: todoReducer,
    login: loginReducer,
    productTransactionData: productTransactionDataReducer,
    orderRecords: orderRecordsReducer,
    tabNav: tabNavReducer
  },
});