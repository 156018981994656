import { createSlice } from "@reduxjs/toolkit";
export const todoSlice = createSlice({
  name: "todo",
  initialState: {
    todolist: [
      { id: 1, name: "first todo on redux" },
      { id: 2, name: "second todo in list" },
    ],
  },
  reducers: {
    addTodo: (state, action) => {
      console.log(action.payload);
      state.todolist.push(action.payload);
    },
  },
});

export const selectTodo = (state: { todo: any; }) => state.todo;
export const { addTodo } = todoSlice.actions;
export default todoSlice.reducer;