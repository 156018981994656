import { createSlice } from "@reduxjs/toolkit";
export const tabNavSlice = createSlice({
  name: "tabNav",
  initialState: {
    defaultActiveKey: 1
  },
  reducers: {
    setDefaultActiveKeyOfTabNav: (state, action) => {
      state.defaultActiveKey = action.payload;
    }
  },
});

export const selectDefaultActiveKeyOfTabNav = (state: { tabNav: any}) => state.tabNav;
export const { setDefaultActiveKeyOfTabNav } = tabNavSlice.actions;
export default tabNavSlice.reducer; 