import ReactDOM from 'react-dom/client';
import { StrictMode, Suspense } from 'react'
import { HashRouter } from "react-router-dom"
import Router from './router'
import reportWebVitals from './reportWebVitals'
import Loading from './components/loading/Loading'
import { Provider } from "react-redux";
import store from "./store";
import './index.css'
import "./i18n.ts"

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <StrictMode>
    <Provider store={store}>
      <HashRouter>
        <Suspense fallback={ <Loading />}>
          <Router />
        </Suspense>
      </HashRouter>
    </Provider>
  </StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
