import { createSlice } from "@reduxjs/toolkit";

export const productTransactionDataSlice = createSlice({
  name: "productTransactionData",
  initialState: {
    productTransactionData: []
  },
  reducers: {
    setProductTransactionData: (state, action) => {
      state.productTransactionData = action.payload;
    },
  },
});

export const selectProductTransactionData = (state: { productTransactionData: any}) => state.productTransactionData;
export const { setProductTransactionData } = productTransactionDataSlice.actions;
export default productTransactionDataSlice.reducer; 