import { useRoutes } from 'react-router-dom';
import { lazy } from 'react'

const App = lazy(() => import('../App'))
const Login = lazy(() => import('../components/login/Login'))
const Home = lazy(() => import('../components/home/Home'))
const StoreReport = lazy(() => import('../components/storesIncome/components/storeReport/StoreReport.tsx'))
const ProductsIncomeReport = lazy(() => import('../components/productsIncome/components/productsIncomeReport/ProductsIncomeReport.tsx'))
const CategoryIncomeReport = lazy(() => import('../components/categoryIncome/components/categoryIncomeReport/CategoryIncomeReport.tsx'))
const OrderRecordsInvoice = lazy(() => import('../components/orderRecords/components/orderRecordsInvoice/OrderRecordsInvoice.tsx'))
const Customer = lazy(() => import('../components/customer/customer.tsx'))
const Admin = lazy(() => import('../components/admin/admin.tsx'))

const routes = [
  {
    path: '/',
    element: <App />,
    children: [
      {
        path: '/login',
        element: <Login />,
      },
      {
        path: '/home',
        element: <Home />,
      },
      {
        path: '/storesIncome/storeReport/:storeId',
        element: <StoreReport />,
      },
      {
        path: '/productsIncome/productsIncomeReport/:productId',
        element: <ProductsIncomeReport />,
      },
      {
        path: '/categoryIncome/categoryIncomeReport/:productCategoryId',
        element: <CategoryIncomeReport />,
      },
      {
        path: '/orderRecords/orderRecordsInvoice/:invoiceId',
        element: <OrderRecordsInvoice />,
      },
      {
        path: '/customer',
        element: <Customer />,
      },
      {
        path: '/admin',
        element: <Admin />,
      }
      // {
      //   path: '/todoList',
      //   element: <TodoList />,
      // },
      // {
      //   path: '/bar',
      //   element: <Bar xAxisData={[]} yAxisData={[]} />,
      // },
    ],
  }
];

export default () => useRoutes(routes);